import share from '~/api/share.js'
import diskutils from '~/api/diskutils.js'
import tapeless from '~/api/tapeless.js'
import ftrack from '~/api/ftrack.js'

const createAPI = (
  ctx,
  baseURL,
  api,
  requestInterceptor,
  responseInterceptor
) => {
  const httpClient = ctx.$axios.create()

  httpClient.setBaseURL(baseURL)

  httpClient.interceptors.request.use(requestInterceptor)

  if (responseInterceptor) {
    httpClient.interceptors.response.use(
      (response) => response,
      responseInterceptor
    )
  }

  return api(httpClient)
}

export default (ctx, inject) => {
  const interceptorConfig = function(config) {
    if (ctx.$auth) {
      config.headers.Authorization = `jwt ${ctx.$auth.getToken('local')}`
    }
    return config
  }

  const app = ctx.app
  const forbiddenResponse = function(error) {
    const code = parseInt(error.response && error.response.status)
    // token expired
    if (code === 401) {
      app.router.push('/logout')
    } else {
      throw error
    }
  }
  const shareAPI = createAPI(
    ctx,
    process.env.shareAPI,
    share,
    interceptorConfig,
    forbiddenResponse
  )  
  const diskutilsAPI = createAPI(
    ctx,
    process.env.diskutilsAPI,
    diskutils,
    interceptorConfig,
    forbiddenResponse
  )
  const tapelessAPI = createAPI(
    ctx,
    process.env.tapelessAPI,
    tapeless,
    interceptorConfig,
    forbiddenResponse
  )
  const ftrackAPI = createAPI(
    ctx,
    process.env.ftrackAPI,
    ftrack,
    interceptorConfig,
    forbiddenResponse
  )
  inject('shareAPI', shareAPI)
  inject('diskutilsAPI', diskutilsAPI)
  inject('tapelessAPI', tapelessAPI)
  inject('ftrackAPI', ftrackAPI)
}
